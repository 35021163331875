import request from "@http";

// 获取减租申请列表
export function getCommunityTotalApi() {
    return request({
        method: "get",
        url: `/api/gzf/baseinfo/chart/apartment/overview`,
    });
}

// 首页 消息数量
export function outstandingCount(params) {
    return request({
        method: 'get',
        url: '/api/alerts/outstanding/count',
        params,
    })
}

// 消息列表
export function alertsCount(params) {
    return request({
        method: 'get',
        url: '/api/alerts/count',
        params,
    })
}

// 小区列表
export function getCommunityListApi() {
    return request({
        method: "get",
        url: `/api/gzf/baseinfo/community`,
    });
}