import _ from "lodash";
import Layout from "@/layout/index";

// 动态判断权限并通过 addRoutes 动态添加的页面
export const asyncRoutes = [
    {
        path: "/",
        component: Layout,
        redirect: "/home",
        children: [
            {
                path: "home",
                name: "Home",
                meta: { title: "首页", keepAlive: true },
                component: () => import("@/views/home"),
            }
        ]
    },
    {
        path: "/",
        component: Layout,
        redirect: "/rental-management",
        children: [
            {
                path: "rental-management",
                name: "rental-management",
                meta: { title: "租户管理", keepAlive: true },
                component: () => import("@/views/rental-management"),
            }
        ]
    },
    {
        path: "/shops-management",
        component: Layout,
        meta: { title: "商铺管理" },
        children: [
            {
                path: "exemption-application",
                name: "exemption-application",
                meta: { requireAuth: true, keepAlive: false, title: "减租申请" },
                component: () => import("@/views/shops-management/exemption-application"),
            },
            {
                path: "message-reminder",
                name: "message-reminder",
                meta: {requireAuth: true, keepAlive: false, title: "消息提醒"},
                component: () => import( /* webpackChunkName: "system-management-management" */ "@/views/message-reminder"),
            },
        ]
    },
    {
        path: "*",
        redirect: "/404",
    },
    {
        path: "/rental-housing-management",
        component: Layout,
        meta: { title: "租房管理" },
        children: [
            {
                path: "housing-resources-management",
                name: "housing-resources-management",
                meta: { title: "房源管理", keepAlive: true },
                component: () => import("@/views/housing-resources-management"),
            },
            {
                path: "publish-create",
                name: "publish-create",
                meta: { title: "新增房型", keepAlive: true },
                component: () => import("@/views/rental-housing-management/publish-create"),
            },
            {
                path: "publish-apartment",
                name: "publish-apartment",
                meta: { title: "发布列表", keepAlive: true },
                component: () => import("@/views/rental-housing-management/publish-apartment"),
            },
            {
                path: "sms-reminder",
                name: "sms-reminder",
                meta: { title: "短信提醒", keepAlive: true },
                component: () => import("@/views/rental-housing-management/sms-reminder"),
            },
            {
                path: "existing-listings",
                name: "existing-listings",
                meta: { title: "现房房源", keepAlive: true },
                component: () => import("@/views/rental-housing-management/existing-listings"),
            },
            {
                path: "listing-application",
                name: "listing-application",
                meta: { title: "房源申请", keepAlive: true },
                component: () => import("@/views/rental-housing-management/listing-application"),
            },
            {
                path: "community-management",
                name: "community-management",
                meta: { title: "小区管理", keepAlive: true },
                component: () => import("@/views/rental-housing-management/community-management"),
            },
            {
                path: "guaranteed-rental-housing-resources",
                name: "guaranteed-rental-housing-resources",
                meta: { title: "保租房房源", keepAlive: true },
                component: () => import("@/views/rental-housing-management/guaranteed-rental-housing-resources"),
            },
        ]
    },
    {
        path: "/system-management",
        component: Layout,
        meta: { title: "系统管理" },
        children: [
            {
                path: "log-management",
                name: "log-management",
                meta: { title: "系统日志", keepAlive: true },
                component: () => import("@/views/system-management/log-management"),
            },
        ]
    },
];
