<template>
     <section class="r-e-tree">
          <div class="tree-name" v-if="showHeader">{{treeName}}</div>
          <div class="tree-search">
               <el-input
                       suffix-icon="el-icon-search"
                       placeholder="请输入关键字"
                       v-model="filterText"
                       @clear="handleClickClearFilter"
                       @keypress.native.enter="handleClickFilter"
                       clearable
               >
               </el-input>
               <el-button size="mini" type="primary" @click="handleClickFilter">查 询</el-button>
          </div>
          <div class="tree-container hidden-scrollbar">
               <el-tree
                       class="filter-tree"
                       ref="tree"
                       highlight-current
                       v-bind="$attrs"
                       :class="{'tree-use-handle': handleIcons}"
                       :data="data"
                       :props="props"
                       :node-key="nodeKey"
                       :expand-on-click-node="expandOnClickNode"
                       :filter-node-method="filterNode"
                       :render-content="renderContent"
                       :default-checked-keys="defaultCheckedKeys"
                       :default-expanded-keys="defaultExpandedKeys"
                       @node-click="handleNodeClick"
                       @check-change="handleNodeChange"
                       @check="handleNodeCheck"
               ></el-tree>
          </div>
     </section>
</template>
<script>
    import _ from "lodash";

    export default {
        props: {
            treeName: {
                type: String,
                default: "小区",
            },
            data: {
                type: Array,
                default: () => [],
            },
            nodeKey: {
                type: String,
                default: "uuid",
            },
            props: {
                type: Object,
                default: () => {
                    return {
                        children: "children",
                        label: "name",
                    };
                },
            },
            // 默认勾选的节点的 key 的数组
            defaultCheckedKeys: {
                type: Array,
                default: () => [],
            },
            // 默认展开的节点的 key 的数组
            defaultExpandedKeys: {
                type: Array,
                default: () => [],
            },
            permission: {
                type: Array,
                default: () => ["admin"],
            },
            // 节点操作类型
            handleIcons: {
                type: Array,
                default: () => [],
            },
            showHeader: {
                type: Boolean,
                default: true,
            },
            expandOnClickNode: {
                type: Boolean,
                default: false,
            },
            // 节点前的icon
            nodeIcon: Function,
            // 节点的名称后缀
            nodeLabel: Function,
            filterType: {
                type: String,
                default: "upgrade",
            },
        },
        name: "r-e-tree",
        data() {
            return {
                filterText: "",
            };
        },
        methods: {
            handleNodeClick(data, node) {
                this.$emit("node-click", data, node);
            },
            handleNodeChange(data, checked, indeterminate) {
                const originalData = { data, checked, indeterminate };
                const checkNodes = this.getCheckedNodesKeys();
                this.$emit("check-change", { ...checkNodes, originalData });
            },
            handleNodeCheck(data, node) {
                this.$emit("check", data, node);
            },
            getKeyNode(key) {
                // 根据 data 或者 key 拿到 Tree 组件中的 node
                return this.setDefaultCurrentNodeKey(key);
            },
            handleClickFilter() {
                // 搜索
                this.$refs.tree.filter(this.filterText);
            },
            handleClickClearFilter() {
                // 清空搜索
                this.$refs.tree.filter(this.filterText);
                this.$emit("on-clear-filter");
            },
            filterNode(value, data, node) {
                if (!value) return true;
                if (this.filterType === "default") {
                    const label = this.props.label;
                    return data[label].indexOf(value) !== -1;
                }
                if (this.filterType === "upgrade") {
                    let _array = []; // 这里使用数组存储 只是为了存储值。
                    this.getReturnNode(node, _array, value);
                    let result = false;
                    _array.forEach(item => {
                        result = result || item;
                    });
                    return result;
                }
            },
            getReturnNode(node, _array, value) {
                let isPass =
                    node.data &&
                    node.data[this.props.label] &&
                    node.data[this.props.label].indexOf(value) !== -1;
                isPass ? _array.push(isPass) : "";
                if (!isPass && node.level !== 1 && node.parent) {
                    this.getReturnNode(node.parent, _array, value);
                }
            },
            setDefaultCurrentNodeKey(key) {
                // 通过 key 设置某个节点的当前选中状态，使用此方法必须设置 node-key 属性
                this.$refs.tree.setCurrentKey(key);
                // 根据 data 或者 key 拿到 Tree 组件中的 node
                return this.getNode(key);
            },
            getNode(key) {
                // 根据 data 或者 key 拿到 Tree 组件中的 node
                return this.$refs.tree.getNode(key);
            },
            setCheckedNodeKey(keys = []) {
                // 通过 keys 设置选择的节点
                this.$refs.tree.setCheckedKeys(keys);
            },
            setCheckedKeys(keys = [], isChecked = false) {
                // 通过 keys 设置目前勾选的节点，使用此方法必须设置 node-key 属性
                this.$refs.tree.setCheckedKeys(keys, isChecked);
            },
            setChecked(key, isChecked = false) {
                // 通过 key / data 设置某个节点的勾选状态，使用此方法必须设置 node-key 属性
                this.$refs.tree.setChecked(key, isChecked);
            },
            getCheckedNodesKeys() {
                const checkedKeys = this.$refs.tree.getCheckedKeys();
                const checkedNodes = this.$refs.tree.getCheckedNodes();
                const halfCheckedKeys = this.$refs.tree.getHalfCheckedKeys();
                const halfCheckedNodes = this.$refs.tree.getHalfCheckedNodes();
                // 筛选条件下返回当前看到的已选的节点
                const filterVisibleNodes = _.cloneDeep(checkedNodes).filter(v => {
                    const { visible = false } = this.getNode(v[this.nodeKey]);
                    return visible;
                });
                return { checkedKeys, checkedNodes, halfCheckedKeys, halfCheckedNodes, filterVisibleNodes };
            },
            renderContent(h, { node, data }) {
                return h(
                    "div",
                    {
                        class: "tree-node-content",
                    },
                    [
                        h(
                            "div",
                            {
                                class: "tree-node-label",
                            },
                            [
                                this.nodeIcon ? this.nodeIcon(h, node, data) : this.createNodeIcon(h, data),
                                h(
                                    "span",
                                    {
                                        class: "tree-node-name",
                                    },
                                    node.label,
                                ),
                                this.nodeLabel(h, node, data)
                            ],
                        ),
                        this.renderPermissionInit(h, { node, data }),
                    ],
                );
            },
            renderPermissionInit(h, { node, data }) {
                const permission = true;
                const _this = this;
                const { nextSibling, previousSibling, level } = node;
                const { type } = data;

                // 所有操作类型 增加、编辑，删除，移动（拖拽/置顶），上移，下移，"plus", "edit", "delete", "rank", "top", "bottom"
                let handleIcons = this.handleIcons;
                // 没有权限或者不需要操作
                if (handleIcons.length === 0 || !permission) return h();
                // 小区和楼幢可以增加新节点，单元不可以
                if ([1, 2].includes(type)) handleIcons = handleIcons.filter(v => !["plus"].includes(v));
                // 根节点不能删除，不能移动
                if (level === 1) handleIcons = handleIcons.filter(v => !["delete", "rank"].includes(v));
                // 当前节点没有前一个兄弟节点，无法上移
                if (!previousSibling) handleIcons = handleIcons.filter(v => v !== "top");
                // 当前节点没有后一个兄弟节点，无法下移
                if (!nextSibling) handleIcons = handleIcons.filter(v => v !== "bottom");
                return h(
                    "div",
                    {
                        class: "tree-node-icon-group",
                    },
                    handleIcons.map(v => {
                        return h(
                            "i",
                            {
                                class: "el-icon-" + v,
                                on: {
                                    click: () => {
                                        _this.$emit("on-node-click", v, { node, data });
                                    },
                                },
                            },
                        );
                    }),
                );
            },
            createNodeIcon(h, { type }) {
                const imgSrc = require("./img/icon-community.png");
                const houseSrc = require("./img/icon-house.png");
                if (type === 3) return h();
                return h(
                    "img",
                    {
                        class: "tree-icon-img",
                        attrs: {
                            src: type === 1 ? imgSrc : houseSrc,
                        },
                    },
                );
            },
        },
    };
</script>

<style lang="scss" rel="stylesheet/scss">
     .r-e-tree {
          width: 100%;
          height: 100%;
          box-sizing: border-box;
          background-color: #fff;
          overflow: hidden;

          .tree-name {
               height: VH(60px);
               line-height: VH(50px);
               font-size: rem(18px);
               font-weight: bold;
               border-bottom: 1px solid #E6E6E6;
               padding-left: VW(15px);
          }

          .tree-search {
               display: flex;
               margin: VH(10px) 0;
               padding-left: VW(15px);

               .el-input {
                    width: 70%;
                    max-width: 190px;

                    .el-input__inner {
                         width: 100%;
                         height: 30px;
                         font-size: rem(12px);
                    }
               }

               .el-input__inner, .el-input__suffix .el-input__icon {
                    line-height: 30px;
               }

               .el-button {
                    margin-left: VW(10px);
               }
          }

          .tree-container {
               width: 100%;
               height: VH(850px);
               padding-left: VW(15px);
          }

          .el-tree {
               height: 100%;

               &.tree-use-handle {
                    .el-tree-node__content {
                         padding-right: VW(130px);

                         .tree-node-content .tree-node-label {
                              padding-right: VW(30px);
                         }
                    }
               }

               & > .el-tree-node {
                    width: 100%;
                    display: inline-block;
               }

               .el-tree-node__content {
                    height: VH(30px);

                    .tree-node-content {
                         width: 100%;
                         font-size: rem(14px);
                         padding: VH(8px) 0;
                         position: relative;
                         display: flex;

                         .tree-node-label {
                              .tree-icon-img {
                                   width: VW(16px);
                                   vertical-align: bottom;
                              }

                              .tree-node-name {
                                   font-size: 12px;
                                   padding: 0 VW(5px);
                              }
                         }

                         .tree-node-icon-group {
                              color: #1890FF;
                              display: none;

                              i {
                                   padding-right: VW(3px);
                              }
                         }
                    }

                    &:hover {
                         .tree-node-icon-group {
                              display: inline-block;
                         }
                    }
               }
          }

          .el-tree--highlight-current .el-tree-node.is-current > .el-tree-node__content {
               background-color: #D8EFFB;
          }
     }
</style>
