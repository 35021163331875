import Cookies from 'js-cookie'

// token
const tokenCookies = {
    set(token) { Cookies.set('Authorization', token) },
    get: () => Cookies.get('Authorization'),
    remove: () => Cookies.remove('Authorization')
}

export {
    tokenCookies
}
