<template>
     <section class="side-bar">
          <div class="side-bar-header flex align-center justify-center">
               <img class="logo" src="../../assets/images/logo.png" alt="">
          </div>
          <div class="side-bar-menu">
               <el-menu
                       class="menu-class"
                       :default-active="activeMenu"
                       unique-opened
                       background-color="transparent"
                       text-color="#fff"
                       active-text-color="#fff"
                       mode="vertical"
                       router
               >
                    <template v-for="item in menuList">
                         <el-menu-item
                                 v-if="!item.children || item.children.length === 0"
                                 :index="item.url"
                                 :key="item.id"
                         >
                              <div class="menu-box flex align-center">
                                   <img :src="item.imgSrc || defaultMenuIcon" alt="">
                                   <div class="menu-name"> {{ item.name }}</div>
                              </div>
                         </el-menu-item>
                         <el-submenu
                                 v-else
                                 class="submenu-class"
                                 :index="item.url"
                                 :key="item.id"
                         >
                              <template slot="title">
                                   <div class="menu-box flex align-center">
                                        <img :src="item.imgSrc || defaultMenuIcon" alt="">
                                        <div class="menu-name"> {{ item.name }}</div>
                                   </div>
                              </template>
                              <el-menu-item
                                      v-for="child in item.children"
                                      :index="`${item.url}/${child.url}`"
                                      :key="child.id"
                                      route
                              >
                                   {{ child.name }}
                              </el-menu-item>
                         </el-submenu>
                    </template>
               </el-menu>
          </div>
     </section>
</template>

<script>
    import constMenu from "@/assets/json/menu.json";

    const defaultMenuIcon = require("@images/icon.png");


    export default {
        name: "side-bar",
        data() {
            return {
                menuList: [],
                defaultMenuIcon
            };
        },
        computed: {
            activeMenu() {
                const route = this.$route;
                const { meta, path } = route;
                if (meta.activeMenu) {
                    return meta.activeMenu;
                }
                return path;
            },
        },
        created() {
            this.menuList = constMenu;
        },
        methods: {}
    };
</script>
<style lang="scss" rel="stylesheet/scss">
     .el-menu {
          border: none;

          .menu-box {
               img {
                    width: VW(17px);
                    height: VH(20px);
               }

               .menu-name {
                    padding-left: VW(15px);
               }
          }

          .el-menu-item,
          .el-submenu__title {
               padding-left: VW(50px) !important;
               background-color: transparent !important;
          }

          .el-submenu__title {
               height: VH(56px);
               line-height: VH(56px);
          }

          .el-menu-item {
               height: VH(46px);
               line-height: VH(46px);
          }

          .el-menu-item.is-active {
               background: linear-gradient(
                               85deg, rgba(165, 171, 255, 0.22) 0%, rgba(31, 36, 106, 0.22) 96%, rgba(25, 30, 99, 0.22) 100%);

          }

          .el-submenu .el-menu-item {
               padding-left: VW(80px) !important;
               text-align: left;
          }

          .el-submenu__title i {
               color: #fff;
          }
     }
</style>
<style lang="scss" scoped>
     .side-bar {
          overflow: hidden;
          background: linear-gradient(180deg, #5194FC 0%, #8154F9 100%);
          box-shadow: 6px 0px 12px rgba(6, 92, 136, 0.16);

          .side-bar-header {
               width: VW(90px);
               height: VW(90px);
               margin: VH(40px) auto;
               border-radius: 50%;
               background-color: #fff;

               img {
                    width: VW(60px);
               }
          }
     }
</style>
