<template>
     <el-drawer
             v-bind="$attrs"
             :visible.sync="drawerVisible"
             :append-to-body="appendToBody"
             :destroy-on-close="destroyOnClose"
             :with-header="withHeader"
             :direction="direction"
             :show-close="showClose"
             :wrapperClosable="wrapperClosable"
             :modal="modal"
             :size="drawerSize"
             :style="drawerStyle()"
     >
          <template slot="title">
               <slot name="title"></slot>
          </template>
          <slot></slot>
     </el-drawer>
</template>

<script>
    import { VwVh } from "@custom/widthHeight";

    const vwVh = new VwVh();

    export default {
        props: {
            visible: {
                type: Boolean,
                default: false,
            },
            // Drawer 自身是否插入至 body 元素上。
            appendToBody: {
                type: Boolean,
                default: false,
            },
            // 控制是否在关闭 Drawer 之后将子元素全部销毁
            destroyOnClose: {
                type: Boolean,
                default: true,
            },
            // 控制是否显示 header 栏,
            withHeader: {
                type: Boolean,
                default: true,
            },
            // Drawer 打开的方向 rtl / ltr / ttb / btt
            direction: {
                type: String,
                default: "rtl",
            },
            showClose: {
                type: Boolean,
                default: true,
            },
            // 是否需要遮罩层
            modal: {
                type: Boolean,
                default: false,
            },
            // 点击遮罩层是否可以关闭 Drawer
            wrapperClosable: {
                type: Boolean,
                default: false,
            },
            size: {
                type: [String, Number],
                default: "30%"
            },
            // drawerWrapper是否全屏
            drawerWrapper: {
                type: Boolean,
                default: false,
            }
        },
        name: "r-e-drawer",
        computed: {
            drawerVisible: {
                get() {
                    return this.visible;
                },
                set(val) {
                    this.$emit("update:visible", val);
                },
            },
            drawerSize() {
                // 生成自适应表格高度
                const typeOf = typeof this.size;
                if (typeOf === "string") return this.size;
                return vwVh.VW(this.size);
            },

        },
        methods: {
            drawerStyle() {
                // 防止drawer的最外层标签el-drawer__wrapper全屏，导致drawer之外的标签无法点击
                const typeOf = typeof this.size;
                if (this.drawerWrapper || typeOf === "string") return {};
                const direction = this.direction;
                const width = vwVh.VW(vwVh.$width - this.size);
                const height = vwVh.VH(vwVh.$height - this.size);

                // Drawer 打开的方向不同，固定定位的方式随之改变
                if (direction === "ttb") return { bottom: height };
                if (direction === "rtl") return { left: width };
                if (direction === "btt") return { top: height };
                if (direction === "ltr") return { right: width };
            }
        }
    };
</script>

<style lang="scss" rel="stylesheet/scss">
     .el-drawer {
          padding: 0 VW(20px) VH(20px) VW(40px);

          .el-drawer__header {
               color: #333;
               font-size: rem(25px);
               font-weight: bold;
               padding: VH(40px) 0 0;
          }
     }
</style>
