import request from "@http";
import qs from "qs";

// 登录
export function loginApi(params) {
    return request({
        method: "POST",
        url: "user/login",
        headers: {
            "app-device-type": "new"
        },
        data: qs.stringify(params),
    });
}

// 退出登录
export function logoutApi() {
    return request({
        method: "POST",
        url: "user/logout",
    });
}

// 用于防止session过期，30分钟调取一次接口，该接口无其它意义
export function connectApi() {
    return request({
        method: "GET",
        url: "api/user/record",
    });
}
